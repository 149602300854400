import React, {useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import prettier from "prettier/standalone";
import babylon from "prettier/parser-babel";
import sqlFormatter from "sql-formatter";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import PropertyRow from './PropertyRow';
import DataWindow from "./DataWindow";
import { ResponsiveNeoGraph } from "./NeoGraph";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      margin: '10px',
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
  }));

//   {
//     "databaseSchema" : "roger's json schema",
//     "selected" : [{"table1" :["attribute1 > 10", "attribute2 <= table2.attribute1", ...]},{"tabel2":[]} , ...], //selected should include all tables regardless they have restrictions or not
//     "show": [{"table1":["attribute1",...]}, ...], // {"MAX":{"table1":["attribute"]}} if you would like to project max(attribute)
//     "groupBy": [{"table1":["attribute1",...]}, ...]]
//     "aggregate": [{"SUM": {"table1" : ["attribute"]}}], // would be empty if there's none, would be {"COUNT":{}} if wishes to count number of rows
//     "aggregateRestriction" : [{table1:["attribute > 10"]}] // restriction after the aggregation operation, would be empty if there's none
//     // Apply restriction to global aggregation example: {"":["* > 1"]} 
//    }

//Need to change ops to include every table
//put having field into aggregate restriction
const noShowErrorTitle = "No attribute is selected to show";
const noShowErrorBody = "Please select at least one attribute to show.";
const noCriteriaErrorTitle = "Empty criteria or value input";
const noCriteriaErrorBody = "Please enter criteria or value";
const noAggrFuncErrorTitle = "No aggregation function";
const noAggrFuncErrorBody = "Please select an aggregation function for attribute";


export default function Queries(props){
    const classes = useStyles();

    const showQueries = props.showQueries;
    const schema = props.schema;
    const ops = props.ops;
    const criteria = props.criteria;
    const show = props.show;
    const aggrs = props.aggr;
    const group = props.group;
    const field_data = props.field_data;
    const display = props.display;
    const having = props.having;
    const havingOp = props.havingOp;

    const [checkedRels, setCheckedRels] = React.useState(props.checkedRels);
    const [relationships, setRelationships] = React.useState(props.relationships);
    const [neo4j, setNeo4j] = React.useState("");
    const [neoflag, setNeoFlag] = React.useState(false);
    const [showRelation, setShowRelation] = React.useState(false);
    const [showRelationHidden, setShowRelationHidden] = React.useState(true);
    const [mongoDB, setMongoDB] = React.useState("");
    const [sql, setSql] = React.useState("");
    const [datalog, setDatalog] =  React.useState("");
    const [showSqlData, setShowSqlData] = React.useState(false);
    const [showMongodbData, setShowMongodbData] = React.useState(false);
    const [showNeo4jData, setShowNeo4jData] = React.useState(false);
    const [neo4jgraphquery, setNeo4jGraphQuery] = React.useState("");
    const [sqlres, setSqlRes] = React.useState("");
    const [mongores, setMongoRes] = React.useState("");
    const [neores, setNeoRes] = React.useState("");
    const [errorTitle, setErrorTitle] = React.useState("");
    const [errorBody, setErrorBody] = React.useState("");
    const [showError, setShowError] =  React.useState(false);

    const neo4juri = 'bolt://34.64.120.100'
    const neo4juser = 'neo4j'
    const neo4jpassword = 'pEtQpY3v'

    useEffect(() => {
        setRelationships(props.relationships);
        setCheckedRels(props.checkedRels);
    }, [props.relationships, props.checkedRels])


    const makeSelected = () => {
        let selected = {}

        Object.entries(ops).map((item) => {
            let tablename = item[0].split('_', 1)
            let attrname = item[0].split(/_(.+)/)[1]
            let critval = criteria.get(item[0])
            if(typeof critval === "undefined") {
                return 0
            }
            
            if(selected.hasOwnProperty(tablename)){
                if (item[1] !== "") {
                    selected[tablename].push(`${attrname} ${item[1]} ${critval}`)
                }
            } else{
                if (item[1] !== "") {
                    selected[tablename] = [`${attrname} ${item[1]} ${critval}`]
                } else {
                    selected[tablename] = []
                }
            }
            return 0
        })
        let checkselected = Object.keys(ops).map((elem) => (
            elem.split('_', 1)[0]
        ))

        field_data.map((item) => {
            if(!checkselected.includes(item.title) && display[item.title].includes(true)){
                selected[item.title] = []
            }
            return 0
        })
 
        let selectedList = Object.entries(selected).map(([table, values]) => (
            {[table]: values}
        ))

        return selectedList
    }

    const makeShow = () => {
        let showobj = {}
        show.forEach((value, key) => {
            if (!value) {
                return;
            }
            let [tableAttr, idx] = key.split('/');
            let tablename = tableAttr.split(/_(.+)/)[0]
            let attrname = tableAttr.split(/_(.+)/)[1]
            idx = parseInt(idx);   

            if (!display[tablename][idx]) {
                return;
            }

            if(showobj.hasOwnProperty(tablename)){
                showobj[tablename].push(`${attrname}`);
            } else{
                showobj[tablename] = [`${attrname}`];
            }
        })

        let showList = Object.entries(showobj).map(([table, values]) => (
            {[table]: values}
        )) 
        return showList
    }

    const makeGroup = () => {
        let groupobj = {}
        group.forEach((value, key) => {
            if (!value) {
                return;
            }

            let tablename = key.split(/_(.+)/)[0]
            let attrname = key.split(/_(.+)/)[1]
            if(groupobj.hasOwnProperty(tablename)){
                groupobj[tablename].push(`${attrname}`)
            } else{
                groupobj[tablename] = [`${attrname}`]
            }
        })
        let groupList = Object.entries(groupobj).map(([table, values]) => (
            {[table]: values}
        ))
        return groupList
    }

    const makeAggr = () => {
        // "aggregate": [{"SUM": {"table1" : ["attribute"]}}]
        let aggritems = Object.entries(aggrs).map((item)=> {
            return `{"${item[1]}": {"${item[0].split('_', 1)}" : ["${item[0].split(/_(.+)/)[1]}"]}}`
        })
        return aggritems
    }

    const makeHaving = () => {
        let aggrRestr = {}
        Object.entries(havingOp).map((item) => {
            let tablename = item[0].split('_', 1)
            let attrname = item[0].split(/_(.+)/)[1]
            let critval = having.get(item[0])
            if(aggrRestr.hasOwnProperty(tablename)){
                if (item[1] !== "") {
                    aggrRestr[tablename].push(`${attrname} ${item[1]} ${critval}`)
                }
            } else{
                if (item[1] !== "") {
                    aggrRestr[tablename] = [`${attrname} ${item[1]} ${critval}`]
                } else {
                    aggrRestr[tablename] = []
                }

            }
            return 0
        })
        
        let selectedList = Object.entries(aggrRestr).map(([table, values]) => (
            {[table]: values}
        ))

        return selectedList
    }

    const addRelation = () => {  
        setShowRelation(!showRelation);  
    }

    // Helper function for string parsing
    var getFromBetween = {
        results:[],
        string:"",
        getFromBetween:function (sub1,sub2) {
            if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
            var SP = this.string.indexOf(sub1)+sub1.length;
            var string1 = this.string.substr(0,SP);
            var string2 = this.string.substr(SP);
            var TP = string1.length + string2.indexOf(sub2);
            return this.string.substring(SP,TP);
        },
        removeFromBetween:function (sub1,sub2) {
            if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
            var removal = sub1+this.getFromBetween(sub1,sub2)+sub2;
            this.string = this.string.replace(removal,"");
        },
        getAllResults:function (sub1,sub2) {
            // first check to see if we do have both substrings
            if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return;
    
            // find one result
            var result = this.getFromBetween(sub1,sub2);
            // push it to the results array
            this.results.push(result);
            // remove the most recently found one from the string
            this.removeFromBetween(sub1,sub2);
    
            // if there's more substrings
            if(this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
                this.getAllResults(sub1,sub2);
            }
            else return;
        },
        get:function (string,sub1,sub2) {
            this.results = [];
            this.string = string;
            this.getAllResults(sub1,sub2);
            return this.results;
        }
    };

    const setShowSqlDataTrigger = () => {  
        setShowSqlData(!showSqlData) 
        let jsonData = `{
            "query": ${JSON.stringify(sql)},
            "schema": ${JSON.stringify(schema)}
        }`
        fetch("/executeSql", {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: jsonData
        }).then(response => {
            response.json().then(data => {
                // Parser
                let parsedResult = "";
                console.log(JSON.stringify(sql))
                let tableHeads = getFromBetween.get(JSON.stringify(sql),"SELECT","FROM")[0];
                console.log(tableHeads)
                tableHeads = tableHeads.replaceAll("\\n","").replaceAll(" ","").replaceAll(",", " | ")
                parsedResult += tableHeads + "\n"
                var k
                for(k = 0; k < tableHeads.length; k++){
                    parsedResult += "-"
                }
                parsedResult += "\n"

                var formatSqlTableResult = data.result.replaceAll('[', '');
                formatSqlTableResult = formatSqlTableResult.replaceAll(']', '');
                let listFormatSqlTableResult = getFromBetween.get(formatSqlTableResult,"(",")");
                
                var i;
                for (i = 0; i < listFormatSqlTableResult.length; i++) {
                    var j;
                    let parsedResultRow = "";
                    if (listFormatSqlTableResult[i].slice(-1) === ","){
                        listFormatSqlTableResult[i] = listFormatSqlTableResult[i].substring(0,listFormatSqlTableResult[i].length-1)
                    }
                    console.log(listFormatSqlTableResult[i]);

                    var count = 0
                    var temp = ""
                    for (var j=0; j<listFormatSqlTableResult[i].length;j++){
                        if (listFormatSqlTableResult[i][j] == "\""){
                            count = count ^ 1;
                        }
                        if (listFormatSqlTableResult[i][j] == "\'" && count == 0){
                            temp = temp + "\"";
                        } else{
                            temp = temp + listFormatSqlTableResult[i][j];
                        }
                    }
                    listFormatSqlTableResult[i] = temp

                    var stringx = "[" + listFormatSqlTableResult[i] + "]";
                    console.log(stringx);
                    var innerListFormatSqlTableResult = JSON.parse(stringx);

                    for (j = 0; j < innerListFormatSqlTableResult.length; j++) {
                        if (innerListFormatSqlTableResult[j] !== ""){
                            if (j === 0) {
                                parsedResultRow += innerListFormatSqlTableResult[j];
                            }else{
                                parsedResultRow += " | " + innerListFormatSqlTableResult[j];
                            }
                        }                        
                    }

                    parsedResultRow += "\n";
                    parsedResult += parsedResultRow;
                }
                setSqlRes(parsedResult)
            })
        })     
    }

    const setShowSqlTableTrigger = () => {  
        setShowSqlData(!showSqlData) 
        let jsonData = `{
            "query": ${JSON.stringify(sql)},
            "schema": ${JSON.stringify(schema)}
        }`
        console.log("test:",jsonData);
        fetch("/executeSqlTable", {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: jsonData
        }).then(response => {
            response.json().then(data => {
                // Parser
                let parsedResult = "";
                var each_table = getFromBetween.get(data.result,"-","+");
                var m;
                for (m=0; m<each_table.length; m++){
                    var table_name = getFromBetween.get(each_table[m],"&","*");
                    // console.log(each_table[m]);
                    parsedResult += "\n*"+table_name
                    parsedResult += "*\ncid  |         name         |     type     | notnull | dflt_value | pk\n";
                    parsedResult += "----------------------------------------------\n";
                    var formatSqlTableResult = each_table[m].replaceAll('[', '');
                    formatSqlTableResult = formatSqlTableResult.replaceAll(']', '');
                    let listFormatSqlTableResult = getFromBetween.get(formatSqlTableResult,"(",")");
    
                    var i;
                    for (i = 0; i < listFormatSqlTableResult.length; i++) {
                        var j;
                        let parsedResultRow = "";
                        if (listFormatSqlTableResult[i].slice(-1) === ","){
                            listFormatSqlTableResult[i] = listFormatSqlTableResult[i].substring(0,listFormatSqlTableResult[i].length-1)
                        }
                        // console.log(listFormatSqlTableResult[i]);
    
                        var count = 0
                        var temp = ""
                        for (var j=0; j<listFormatSqlTableResult[i].length;j++){
                            if (listFormatSqlTableResult[i][j] == "\""){
                                count = count ^ 1;
                            }
                            if (listFormatSqlTableResult[i][j] == "\'" && count == 0){
                                temp = temp + "\"";
                            } else{
                                temp = temp + listFormatSqlTableResult[i][j];
                            }
                        }
                        listFormatSqlTableResult[i] = temp
    
                        var stringx = "[" + listFormatSqlTableResult[i] + "]";
                        // console.log(stringx);
                        var innerListFormatSqlTableResult = JSON.parse(stringx);
    
                        for (j = 0; j < innerListFormatSqlTableResult.length; j++) {
                            if (innerListFormatSqlTableResult[j] !== ""){
                                var lenx;
                                if (j == 0){lenx = 4};
                                if (j == 1){lenx = 20};
                                if (j == 2){lenx = 12};
                                if (j == 3){lenx = 7};
                                if (j == 4){lenx = 10};
                                if (j == 5){lenx = 4};

                                if (innerListFormatSqlTableResult[j].length<lenx){
                                    var delta = lenx - innerListFormatSqlTableResult[j].length;
                                    var n=0;
                                    for (n=0;n<delta;n++){
                                        innerListFormatSqlTableResult[j] += " ";
                                    }
                                }

                                if (j === 0) {
                                    parsedResultRow += innerListFormatSqlTableResult[j];
                                }else{
                                    parsedResultRow += " | " + innerListFormatSqlTableResult[j];
                                }
                            }                        
                        }
    
                        parsedResultRow += "\n";
                        parsedResult += parsedResultRow;
                    }
                }

                setSqlRes(parsedResult)
            })
        })     
    }


    const setShowMongdbDataTrigger = () => {  
        setShowMongodbData(!showMongodbData)  
        let jsonData = `{
            "query": ${JSON.stringify(mongoDB)},
            "schema": ${JSON.stringify(schema)}
        }`

        fetch("/executeMongo", {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: jsonData
        }).then(response => {
            response.json().then(data => {
                setMongoRes(JSON.stringify(data.result, null, 2));
            })
        })    
    }

    // warning, temporary comment out some code
    const setShowNeo4jDataTrigger = () => {  
        setShowNeo4jData(!showNeo4jData)   
        console.log("flag is",neoflag);
        if (neoflag) {
            let jsonData = `{
                "query": ${JSON.stringify(neo4j)},
                "schema": ${JSON.stringify(schema)}
            }`
            console.log("query is",jsonData["query"]);
            console.log("schema is",jsonData["schema"]);
            fetch("/executeNeo", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: jsonData
            }).then(response => {
                response.json().then(data => {
                    console.log(data.result)
                    setNeoRes(data.result)
                })
            })  
        } else {
            var stmt = neo4j.split("RETURN");
            var return_stmt = stmt.slice(-1)[0];
            var remant = stmt[0];
            return_stmt = return_stmt.replace(" ", "");
            var node_list = return_stmt.split(",");
            var new_return_stmt = "RETURN " + node_list[0].split(".")[0]
            for (var j = 1; j < node_list.length; j++) {
                new_return_stmt += ", " + node_list[j].split(".")[0];
            }
            var query = remant + new_return_stmt;
            setNeo4jGraphQuery(query)
        }
    }

    const handleGenerate = event => {
        let selectedList = makeSelected()
        let showItems = makeShow()
        let groupItems = makeGroup()
        let aggrItems = makeAggr()
        let aggrRestr = makeHaving()
        //for relationship, add variable that calls function to parse 
        if (groupItems.length !== 0){

            if (aggrItems.length < showItems.length) {
                setShowError(true);
                setErrorTitle(noAggrFuncErrorTitle);
                setErrorBody(noAggrFuncErrorBody);
                return;
            }

            console.log(groupItems);
            console.log(aggrItems);
            console.log(showItems);
            showItems = aggrItems.map(v => JSON.parse(v));
        }

        if (showItems.length === 0) {
            setShowError(true);
            setErrorTitle(noShowErrorTitle);
            setErrorBody(noShowErrorBody);
            return;
        }

        let error = false;
        criteria.forEach((value, key, map) => {
            if (ops[key] !== undefined && ops[key] !== "" && value === "") {            
                setShowError(true);
                setErrorTitle(noCriteriaErrorTitle);
                setErrorBody(noCriteriaErrorBody);
                error = true;
            }
        });

        if (error) {
            return;
        }

        having.forEach((value, key, map) => {
            if (havingOp[key] !== undefined && havingOp[key] !== "" && value == "") {
                setShowError(true);
                setErrorTitle(noCriteriaErrorTitle);
                setErrorBody(noCriteriaErrorBody);
                error = true;
            }
        });

        if (error) {
            return;
        }

        let jsonData = `{
            "databaseSchema": ${JSON.stringify(schema)},
            "table": {
                "selected": ${JSON.stringify(selectedList)},
                "show": ${JSON.stringify(showItems)},
                "groupBy": ${JSON.stringify(groupItems)},
                "aggregate": [${aggrItems}],
                "aggregateRestriction": ${JSON.stringify(aggrRestr)}
            }
        }`
        //add separate relation json schema above
        fetch("/operation" , {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: jsonData
        }).then(response => {
                response.json().then(data => { 
                    const formatMongo = prettier.format(data.mongoDB, {
                        parser: "babel",
                        plugins: [babylon]
                    });

                    setMongoDB(formatMongo);
                    setNeo4j(data.neo4j)
                    setNeoFlag(data.neoflag)
                    setSql(sqlFormatter.format(data.sql))
                    setDatalog(data.datalog)
                    setShowRelationHidden(false)
                })});
        
        // Set all show data trigger to false
        setShowMongodbData(false);
        setShowNeo4jData(false);
        setShowSqlData(false);
    }

    if (!showQueries){
        return null
    }else{
        return(
            <>
                <Grid className={classes.root}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper} variant="outlined">
                                <Grid container spacing={0}>
                                    <Grid container>Queries</Grid>
                                    <Grid container justify="flex-end">
                                        <Box mr={1} mb={3}>
                                            <Button onClick={handleGenerate} variant ="contained" align="right">
                                                Generate
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grow 
                            in={datalog.length !== 0 && sql.length !== 0 && mongoDB.length !== 0 && neo4j.length !== 0}
                            style={{ transformOrigin: "top center" }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper} variant="outlined">
                                        Datalog 
                                        {datalog.length !== 0 ?
                                            <pre>
                                                <code>
                                                    <SyntaxHighlighter language="text" theme={dark}>
                                                        {datalog}
                                                    </SyntaxHighlighter>
                                                </code>
                                            </pre>
                                            :
                                            <Box mb={7}>
                                            </Box>
                                        }
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper} variant="outlined">
                                        <Grid container spacing={0}>
                                            <Grid container>SQL</Grid>
                                            <Grid container justify="flex-end">
                                                <Box mr={1} mb={2}>
                                                    <Button onClick={setShowSqlDataTrigger} disabled = {sql===""} variant ="contained" align="right">
                                                        Show SQL Data
                                                    </Button>
                                                    <Button onClick={setShowSqlTableTrigger} disabled = {sql===""} variant ="contained" align="right">
                                                        Show SQL Table
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <pre>
                                            <code>
                                                {sql.length !== 0 ? 
                                                    <SyntaxHighlighter language="sql" theme={dark}>
                                                        {sql}
                                                    </SyntaxHighlighter>
                                                    : null}
                                            </code>
                                        </pre>
                                        {showSqlData ? <DataWindow data={sqlres}/> : null}
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper} variant="outlined">
                                        
                                    <Grid container spacing={0}>
                                        <Grid container>MongoDB</Grid>
                                        <Grid container justify="flex-end">
                                            <Box mr={1} mb={2}>
                                                <Button onClick={setShowMongdbDataTrigger} disabled = {mongoDB===""} variant ="contained" align="right">
                                                    Show MongoDB Data
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                        <pre>
                                            <code>
                                                {mongoDB.length !== 0 ? 
                                                    <SyntaxHighlighter language="javascript" theme={dark}>
                                                        {mongoDB}
                                                    </SyntaxHighlighter>
                                                    : null}
                                            </code>
                                        </pre>
                                        {showMongodbData ? <DataWindow data={mongores}/> : null}
                                        
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper} variant="outlined">
                                        <Grid container spacing={0}>
                                            <Grid container>
                                                Neo4j
                                            </Grid>
                                            <Grid container justify="flex-end" spacing={3}>
                                                <Grid item>
                                                    <Button onClick={addRelation} disabled = {showRelationHidden} variant ="contained" align="left">
                                                        +relationship
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Box mr={1} mb={2}>
                                                        <Button onClick={setShowNeo4jDataTrigger} disabled = {neo4j===""} variant ="contained" align="right">
                                                            Show Neo4j Data
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <pre>
                                            <code>
                                                {neo4j.length !== 0 ? 
                                                    <SyntaxHighlighter language="javascript" theme={dark}>
                                                        {neo4j}
                                                    </SyntaxHighlighter>
                                                    : null}
                                            </code>
                                        </pre>
                                        {showNeo4jData && neoflag ? <DataWindow data={neores}/> : null}
                                        {showNeo4jData && !neoflag ? 
                                        <ResponsiveNeoGraph
                                            containerId={"id1"}
                                            neo4jUri={neo4juri}
                                            neo4jUser={neo4juser}
                                            neo4jPassword={neo4jpassword}
                                            query={neo4jgraphquery}
                                        /> 
                                        : null}
                                    </Paper>
                                </Grid>
                                <Grow 
                                    in={showRelation}
                                    style={{transformOrigin : "top center"}}
                                >
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper} variant="outlined">
                                            Select Neo4j Relations
                                            <PropertyRow data={relationships} neoquery={neo4j} schema = {schema}/>             
                                        </Paper>
                                    </Grid>
                                </Grow>
                            </Grid>
                        </Grow>
                    </Grid>
                    
                </Grid>
                <Dialog 
                    open={showError} 
                    onClose={() => setShowError(false)} 
                    aria-labelledby="error-dialog-title" 
                    aria-describedby="error-dialog-description"
                >
                    <DialogTitle id="error-dialog-title">{errorTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="error-dialog-description">
                            {errorBody}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowError(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

// {relationships.map((row, index) => {
//     return <PropertyRow data={row} key = {row.name+row.nodes[0]+row.nodes[1]} display={checkedRels}
//     handleShow={relationShow} //needs to be replaced with specific relation function
//     handleCrit={handleCrit} 
//     handleOp={handleOp}
//     handleAggr={handleAggr} />
// })}